(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name people.filter:name
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('people')
    .filter('name', name);

  /**
   * Make a name from a person object
   *
   * @returns {Function}
     */
  function name() {
    return function (person, emptyStr) {

      var name = emptyStr;

      if (!angular.isDefined(person)) {
        return emptyStr;
      }

      var first = angular.isDefined(person.first_name) ? person.first_name.replace(/^\s+|\s+$/g, '') : '';
      var last = angular.isDefined(person.surname) ? person.surname.replace(/^\s+|\s+$/g, '') : '';

      if (first != '') {
        name = first;

        if (last != '') {
          name += ' ' + last;
        }
      }

      return name;
    };
  }
}());
